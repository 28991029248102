import React, { Fragment, useState } from 'react';
import { CheckSquare, PlusCircle, Watch } from 'react-feather';
import { useMutation } from '@apollo/react-hooks';

import { Modal, Dropdown } from '@axeedge/go-teacher-components';
import SaveConfirm from '../../../../../../../../components/SaveConfirm';

import { ADD_CLASS_TEACHER_MUTATION, UNARCHIVE_CLASS } from '../../services/graphql';
import { GET_SCHOOL_QUERY, GET_ARCHIVED_CLASSES } from '../../../../../../../../services/school/graphql';
import EditClass from '../EditClass';
import styles from './ClassItem.module.scss';


const ClassItem = ({ classData, school, teachers, setShowAssignToEvent, classesMovedToEvent, setClassesMovedToEvent, archivedTab }) => {
    const [showTeachersModal, setShowTeachersModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);


    const [addTeacher] = useMutation(ADD_CLASS_TEACHER_MUTATION, {
        update: (_, { data }) => {
            if (data.addClassTeacher.errors && data.addClassTeacher.errors.length) {
                alert(data.addClassTeacher.errors[0]);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_SCHOOL_QUERY, variables: { schoolId: school.id } }]
    });


    const [unarchiveStudentsClass, { loadingUnArchive }] = useMutation(UNARCHIVE_CLASS, {
        update: (_, { data }) => {
            if (data.unarchiveStudentsClass.errors && data.unarchiveStudentsClass.errors.length) {
                alert(data.unarchiveStudentsClass.errors[0]);
                return;
            }
        },
        refetchQueries: [
            { query: GET_SCHOOL_QUERY, variables: { schoolId: school.id } },
            { query: GET_ARCHIVED_CLASSES, variables: { schoolId: school.id } }
        ]
    });

    const isClassTeacher = teacher => {
        return classData.teachers.find(t => t.id === teacher.id);
    }

    return (
        <Fragment>
            <div className={styles.class}>
                <div className={styles.classHeader}>
                    <div className={styles.classHeaderLeft}>
                        <div className='u-display-flex'>
                            <p className={styles.className}>{classData.name}</p>
                            <p className={styles.classYear}>Year {classData.description}</p>
                        </div>
                        {classesMovedToEvent && classesMovedToEvent.id === classData.id && <SaveConfirm classes='u-m-top-0 u-m-base-0 u-m-left-2' hideConfirm={setClassesMovedToEvent} />}
                    </div>
                    <Dropdown>
                        <li><button onClick={() => setShowEditModal(true)} className={styles.classEditButton}>Class settings</button></li>
                        {!classData.archived && <li><button onClick={() => setShowAssignToEvent(classData)} className={styles.classEditButton}>Assign class to this event</button></li>}
                        {classData.archived && <li>
                            <button
                                disabled={loadingUnArchive}
                                onClick={() => unarchiveStudentsClass({
                                    variables: { studentsClassId: classData.id }
                                })}
                                className={styles.classEditButton}
                            >{loadingUnArchive ? 'Saving...' : 'Unarchive class'}</button></li>
                        }
                    </Dropdown>
                </div>
                <div className={styles.classContent}>
                    {archivedTab ? (
                        <div className={styles.classContentTeachers}>
                            {classData.inactiveTeachers && classData.inactiveTeachers.map(teacher => (
                                <div key={`class-t-${teacher.id}`} className={styles.classTeacher}>
                                    <p className={styles.classTeacherName}>{teacher.displayName}</p>
                                </div>
                            ))}
                        </div>
                    ) :
                        <div className={styles.classContentTeachers}>
                            {
                                classData.teachers && classData.teachers.length ? classData.teachers.map(teacher => {
                                    return (
                                        <div key={`class-t-${teacher.id}`} className={styles.classTeacher}>
                                            {!teacher.active && <Watch className={styles.classTeacherIcon} />}
                                            <p className={styles.classTeacherName}>{teacher.displayName}</p>
                                        </div>

                                    )
                                }) : <p>No teachers</p>
                            }
                            <button className={styles.classButton} onClick={() => setShowTeachersModal(true)}><PlusCircle className={styles.classButtonIcon} />Add teacher</button>
                        </div>
                    }

                    <div className={styles.classContentPacks}>
                        {/* {
                            classData.classPacks && classData.classPacks.length ? classData.classPacks.map(cPack => {
                                return (
                                    <div key={`class-t-${cPack.id}`} className={styles.classPack}>
                                        <p>{cPack.bookTemplateCategory ? cPack.bookTemplateCategory.name : '1 Pack'}</p>
                                        {!cPack.bookTemplate && <Watch className='u-text-muted u-m-left-1' size='16' />}
                                    </div>

                                )
                            }) : !classData.archived && <p>No packs</p>
                        } */}
                    </div>
                </div>
            </div>
            {
                showTeachersModal && (
                    <Modal closeModal={() => setShowTeachersModal(false)}>
                        <h1>Add Teachers</h1>
                        {
                            teachers.map(teacher => {
                                return (
                                    <button
                                        className={styles.classAddTeacherButton}
                                        key={`add-t-${teacher.id}`}
                                        onClick={() => addTeacher({
                                            variables: {
                                                studentsClassId: classData.id,
                                                teacherId: teacher.id
                                            }
                                        })}
                                    >
                                        {teacher.displayName}
                                        {isClassTeacher(teacher) ? <CheckSquare /> : <PlusCircle />}
                                    </button>
                                )
                            })
                        }
                    </Modal>
                )
            }

            {
                showEditModal && (
                    <Modal closeModal={() => setShowEditModal(false)}>
                        <EditClass schoolId={school.id} classItem={classData} closeModal={() => setShowEditModal(false)} />
                    </Modal>
                )
            }
        </Fragment>
    );
}

export default ClassItem;
